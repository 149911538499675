import React from 'react'

import accommodation from './icon-set/accommodation.svg'
import boat from './icon-set/boat.svg'
import buggy from './icon-set/buggy.svg'
import downwind from './icon-set/downwind.svg'
import flatwater from './icon-set/flatwater.svg'
import hydrofoil from './icon-set/hydrofoil.svg'
import landboard from './icon-set/landboard.svg'
import nowind from './icon-set/nowind.svg'
import radio from './icon-set/radio.svg'
import snow from './icon-set/snow.svg'
import wave from './icon-set/wave.svg'

const icon = (props) => {
  const iconMap = {
    accommodation: accommodation,
    waves: wave,
    flatwater: flatwater,
    hydrofoil: hydrofoil,
    downwinder: downwind,
    snowkite: snow,
    landboard: landboard,
    buggy: buggy,
    boat: boat,
    nowind: nowind,
    radiohelmet: radio
  }

  const componentStyle = {
    backgroundImage: `url(${iconMap[props.children]})`,
    display: 'inline-block',
    width: '1em',
    height: '1em',
    backgroundSize: '1em 1em',
    border: '1px solid var(--colour-body)',
    borderRadius: 'var(--s-5)',
    padding: 'var(--s-2)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '0 var(--s-5)'
  };

  return <span className="icon" style={componentStyle} title={props.text}></span>
}

export default icon