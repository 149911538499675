import React from 'react'

import Icon from '../Icon/Icon'
import airushCarve from '../Cover/airush-carve.jpg'
import schoolStyles from './school.module.css'

const School = (props) => {
  const aboutMaxLength = 100;

  const getSchoolClasses = (school) => {
    const cssClasses = ['stack', 'stack-condensed', schoolStyles.school]
    if (school.promoted) {
      cssClasses.push(schoolStyles.promoted)
    }
    return cssClasses.join(' ')
  }

  const truncate = (originalText, length) => {
    if (!originalText) {
      return ''
    }
    // let shortenedText = ''
    if (originalText.length > length) {
      // shortenedText = originalText.substr(0, length - 3) + '...'
      return originalText.substr(0, length - 3) + '...'
    } else {
      // shortenedText = originalText
      return originalText
    }
    // return shortenedText
  }

  const getArrowClass = () => {
    return props.school.expanded ? 'arrow-up' : 'arrow-down'
  }

  const getAboutSection = (school) => {
    return <p>
      {school.expanded ? props.school.about : truncate(props.school.about, aboutMaxLength)}
      &nbsp;
      {school.about && (school.about.length > aboutMaxLength) ? <span onClick={() => {props.expand()}} className={`arrow ${getArrowClass()}`}></span> : ''}
    </p>
  }

  const backgroundStyle = {
    backgroundSize: 'cover'
  }

  if (props.school.promoted) {
    backgroundStyle.backgroundColor = '#B7DBCF'
    backgroundStyle.backgroundImage = `linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ), url(${airushCarve})`
  } else {
    backgroundStyle.backgroundColor = 'var(--colour-inverse)'
    // TODO: Move the following image data to a separate file. Something like patterns.js or backgrounds.js?
    backgroundStyle.backgroundImage = `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%231d828a' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E")`
  }

  return <div className={getSchoolClasses(props.school)} style={backgroundStyle}>
    <h4>{props.school.name}</h4>
    <div>{props.school.features.map((feature, i) => <Icon key={i} text={feature}>{feature}</Icon>)}</div>
    <address>
      {props.school.address}<br />
      {props.school.phone}<br />
      <a href={props.school.website}>{props.school.website}</a><br />
      <a href={`mailto:${props.school.email}`}>{props.school.email}</a><br />
    </address>
    {getAboutSection(props.school)}
  </div>
}

export default School